import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../../components/layout";
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../../components/common/Button";
import BodyHeader from "../../../components/layout/BodyHeader";

// modals
import HelpModal from "../../../components/modals/HelpModal";

// context
import { useUser } from "../../../components/context/UserContext";

// redux actions
import { materialAction } from "../../../redux/actions/materialActions";

// helpers
import FileService from "../../../services/fileService";
import CrystallizationService from "../../../services/crystallization";
import SolubilityScreening from "./SolubilityScreening";
import Tool1 from "./Tool1";
import Tool2Screening from "./Tool2/Tool2Screening";
import Cosmo from "./Cosmo/Cosmos";
import { APPS_AND_MODELS_CRYSTALLIZATION_PAGE_TITLE, layoutTitles } from "../../../utils/constant";
import CosmoRevised from "./CosmoRevised/CosmoRevised";

const ModeOptions = {
  SolubilityScreening: "solubility-screening",
  Tool1: "tool-1",
  Tool2: "tool-2",
  Cosmo: "cosmo",
  CosmoRevised: "cosmoRevised"

};


export default function Crystallization() {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [mode, setMode] = useState("");
  const [showCredits, setShowCredits] = useState(false);


  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);

  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "/apps-and-models",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelp(true)
    }
  ];

  async function handleFormSubmit(data: any, file: any) {

    setLoading(true);
    try {
      const fileResponse = await FileService.create("/files/upload", {
        id: "",
        file_for: "Crystallization",
        files: [{
          key: file.name,
          mimeType: file.type,
        }],
      });
      if (fileResponse.status === 200) {
        const fileRes = fileResponse.data.body.files[0];
        const blob = new Blob([file], { type: file.type });
        const uploadResponse = await fetch(fileRes.signedUrl,
          {
            method: "PUT",
            body: blob,
          });
        if (uploadResponse.status === 200) {
          const simulationResponse = await CrystallizationService.simulate({
            ...data,
            file_path: fileRes.location.split("amazonaws.com/").slice(-1)[0],
          });
          const resData = await simulationResponse.data.body;
          setResponseData(resData);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const handleButtonClick = (selectedMode: string) => {
    setMode(selectedMode);
    setShowCredits(true);
  };

  return (
    <Layout title={layoutTitles.appsCrystallization}>
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Apps & Models"}
        content={"Apps & Models"} />

      <BodyHeader title={APPS_AND_MODELS_CRYSTALLIZATION_PAGE_TITLE} showBackButton onBackClick={() => history.back()} buttons={headerButtons} />

      <div className="theme-card admin-h flex-col">
        <div className="w-full flex-1 px-8 py-6">
          {
            mode === ModeOptions.SolubilityScreening &&
            <SolubilityScreening handleFormSubmit={handleFormSubmit} responseData={responseData}
              setResponseData={setResponseData} />
          }
          {
            mode === ModeOptions.Tool1 &&
            <Tool1 />
          }
          {
            mode === ModeOptions.Tool2 &&
            <Tool2Screening handleFormSubmit={handleFormSubmit} responseData={responseData}
              setResponseData={setResponseData} />
          }
          {
            mode === ModeOptions.Cosmo &&
            <Cosmo />
          }
          {
            mode === ModeOptions.CosmoRevised &&
            <CosmoRevised />
          }
        </div>
        {showCredits &&
          <div className="credits-message" style={{ fontSize: "14px", color: "#555", textAlign: "left" }}>
            Model created and deployed by Khelan Patel, contact at khelan.patel@gmail.com for any questions.
          </div>
        }
        <div className="flex gap-4 w-full px-8 py-6 justify-center items-center flex-wrap sm:px-8">
          <button type="button" className="theme-btn mb-6 btn-md" onClick={() => handleButtonClick(ModeOptions.SolubilityScreening)}>Solubility Screening</button>
          <button type="button" className="theme-btn mb-6 btn-md" onClick={() => handleButtonClick(ModeOptions.Tool1)}>Tool 1</button>
          <button type="button" className="theme-btn mb-6 btn-md" onClick={() => handleButtonClick(ModeOptions.Tool2)}>Tool 2</button>
          <button type="button" className="theme-btn mb-6 btn-md" onClick={() => handleButtonClick(ModeOptions.Cosmo)}>COSMO</button>
          <button type="button" className="theme-btn mb-6 btn-md" onClick={() => handleButtonClick(ModeOptions.CosmoRevised)}>COSMO Revised</button>
        </div>
      </div>
      {loading ? <div className="theme-loader show fixed">
        <div className="loader-outer">
          <div className="loader"></div>
        </div>
      </div> : ""}
    </Layout>
  );
};

