import React, { useEffect, useState } from "react";
import Select from "react-select";
import Table from "./Table";
import ContourGraph from "../ContourGraph";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";
import SolventListModal from "./SolventListModal";
import { useUser } from "../../../../components/context/UserContext";
import { VendorProps } from "../../../../types/materialsTypes";
import { vendorNames } from "../../../../utils/materialHelper";
import { sortItemsByKey, uniqueItemsByKey } from "../../../../utils/common";
import { useOktaAuth } from "@okta/okta-react";
import MaterialService from "../../../../services/materialService";
import { apiRoutes } from "../../../../utils/apiRoutes";
import { alertCloseAction, alertOpenAction } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import CustomLoader from "../../../../components/common/CustomLoader";
import { toast } from "react-toastify"; // Ensure this is imported
import "react-toastify/dist/ReactToastify.css"; // Toastify styles

const CosmoRevised = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
  const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [vendorName, setVendorName] = useState<string>("");
  const [material, setMaterial] = useState<string>("");
  const [lotNumber, setLotNumber] = useState<string>("");
  const [isChecking, setIsChecking] = useState<boolean>(false); // Added state for checking
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false); // For download button
  const [logStatus, setLogStatus] = useState<string>(""); // For logging status
  const [runOutput, setRunOutput] = useState<string>("");
  const [casNumber, setCasNumber] = useState<string>("");
  const [smiles, setSmiles] = useState<string>("");

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";


interface LogEntry {
  task: string;
  stdout: string;
  stderr: string;
}
  // method to return material list based on selected vendor_name field.
  const getMaterialNames = async (vendor_name: string) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      vendor_name,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.create(
      apiRoutes.LIST_MATERIAL_NAMES_MATERIAL,
      payload
    );
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        const materials = sortItemsByKey(
          uniqueItemsByKey(res?.data?.body, "material_name"),
          "material_name"
        );
        setMaterialNameOptions(materials);
      } else {
        setMaterialNameOptions([]);
        setMaterial("");
        setLotNumber("");
        setLotNumberOptions([]);
      }
    } else {
      setMaterialNameOptions([]);
      setMaterial("");
      setLotNumber("");
      setLotNumberOptions([]);
      dispatch(alertOpenAction("No related materials present.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
    setLoading(false);
  };

  // method to return lot number based on selected vendor_name and material_name fields.
  const getLotNumbers = async () => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      material_name: material,
      vendor_name: vendorName,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.create(
      apiRoutes.LIST_LOT_NUMBERS_MATERIAL,
      payload
    );
    if (res?.data?.code === 200) {
      if (res?.data?.body?.length) {
        const lotnumbers = sortItemsByKey(
          uniqueItemsByKey(res?.data?.body, "lot_number"),
          "lot_number"
        );
        setLotNumberOptions(lotnumbers);
        setLotNumber(lotnumbers[0].lot_number);
      } else {
        setLotNumberOptions([]);
        setLotNumber("");
      }
    } else {
      setLotNumberOptions([]);
      setLotNumber("");
      dispatch(
        alertOpenAction("Unable to load the related Lot Numbers.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
    setLoading(false); // disable loading
  };
  const getMaterial = async (vendor_name: string, material_name: string, lot_number: string) => {
    const payload = {
        uid: `${auth?.claims?.uid}`,
        vendor_name,
        material_name,
        lot_number,
    };
    setLoading(true);
    const res = await MaterialService.create(apiRoutes.FIND_MATERIAL, payload);

    setLoading(false);
    if (res?.data?.code == 200) {
        setCasNumber(res?.data?.body?.cas_number); 
        setSmiles(res?.data?.body?.smiles);
        // dispatch(materialAction(res?.data?.body));
        // if (res?.data?.body?.id) getSaveSimulations(res?.data?.body?.id);
    } else {
        dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return null;
    }
}

  useEffect(() => {
    if (!!vendorName) getMaterialNames(vendorName);
  }, [vendorName]);

  useEffect(() => {
    if (!!vendorName && !!material) getLotNumbers();
  }, [material]);
  useEffect(() => {
    if (!!lotNumber) getMaterial(vendorName, material, lotNumber);
  }, [lotNumber]);

  // Function to toggle the modal
  const toggleModal = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file ? file.name : null);
  };
  const handleRun = async () => {
    setLoading(true);
    try {
      const payload ={
        function_name:"AddMolecule",
        data:{
        compoundName:material,
        cas:casNumber,
        smiles:smiles,
        },
      };
      const response = await fetch(`${apiRoutes.COSMO_API_URL}/run_function`, {
        method: "POST",
        headers: {
          "accept": "application/json",
          "Accept-Language": "en-US,en;q=0.9",
          "Cookie": "ajs_anonymous_id=cd771a39-3232-4042-ab5b-ecbaaf01cb4c; streamlit_xsrf=2|eaefd9ce|56e1111e6daf9c6af432d5aa85534904",
          "Sec-Fetch-Dest": "empty",
          "Referer": "https://lab-w10-28853.corp.mpi.com:8000/docs",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-origin",
          "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36 Edg/131.0.0.0",
          "sec-ch-ua": '"Microsoft Edge";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"Windows"',

        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setRunOutput("Process completed successfully. Check logs for details.");
        toast.success("Run process was successful.");
      } else {
        throw new Error("Run process failed.");
      }
    } catch (error) {
      console.error("Error during run:", error);
      toast.error("Run process encountered an error.");
    } finally {
      setLoading(false);
    }
  };
  const handleCheckStatus = async () => {
    setIsChecking(true);
    try {
      const response = await fetch(`${apiRoutes.COSMO_API_URL}/job_logs`, {
        method: "GET",
        headers: {
          "Accept-Language": "en-US,en;q=0.9",
          "Cookie": "ajs_anonymous_id=cd771a39-3232-4042-ab5b-ecbaaf01cb4c; _streamlit_xsrf=2|eaefd9ce|56e1111e6daf9c6af432d5aa85534904|1734159217",
          "Referer": "https://lab-w10-28853.corp.mpi.com:8000/docs",
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-origin",
          "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36 Edg/131.0.0.0",
          "accept": "application/json",
          "sec-ch-ua": '"Microsoft Edge";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"Windows"',
        },
      });
      const data:{ logs: LogEntry[] }= await response.json();
      if (response.ok) {
        const startTime = new Date();
        startTime.setMinutes(startTime.getMinutes() - 5);
        const relevantLog = data.logs.find((log: LogEntry) => {
          const logTime = new Date(log.stdout.substring(1, 9)); // Extract date from log
          return (
            log.stdout.includes(material) &&
            log.stdout.includes(smiles) &&
            logTime >= startTime
          );
        });
        if (relevantLog) {
          setLogStatus(
            `Job logs:\n${JSON.stringify(
              relevantLog.stdout || "No logs available",
              null,
              2
            )}`
          );
          if (
            relevantLog.stdout.includes(`JOB ${material} SUCCESSFUL`) ||
            relevantLog.stderr.includes("Error") 
          ) {
            setIsDownloadEnabled(true);
            toast.success("Process completed. Download is now enabled.");
          } else {
            toast.info("Process is still running. Please check again later.");
          }
        } else {
          toast.info("No matching logs found for this compound.");
        }
      } else {
        throw new Error("Failed to fetch logs.");
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast.error("Could not fetch logs. Please try again later.");
    } finally {
      setIsChecking(false);
    }
  };
  return (
    <div className="">
      {loading && <CustomLoader />}
      <SolventListModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6 p-4 rounded-lg shadow-sm">
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="vendor">
              <span className="form-label">Vendor</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setVendorName(e.target.value);
                  }}
                  value={vendorName}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {vendors?.map((vendor: VendorProps, index: number) => (
                    <option key={index} value={vendor?.value}>
                      {vendor.label}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="material_name">
              <span className="form-label">Material Name</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setMaterial(e.target.value);
                  }}
                  value={material}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {materialNameOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.material_name}>
                      {name?.material_name}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group ">
            <label
              className="ip-wrap required"
              htmlFor="lot_number"
              title={"Lot Number"}
            >
              <span className="form-label">Lot Number</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setLotNumber(e.target.value);
                  }}
                  value={lotNumber}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {lotNumberOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.lot_number}>
                      {name?.lot_number}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Create a COSMO molecule file</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col gap-4">
          {/* Optional Parameters */}
          <div className="flex flex-col-3 gap-4 w-full">
            <label className="form-label text-sm text-gray-700 justi">
              Optional Parameters
            </label>

            <div className="flex items-center gap-2">
              <input type="checkbox" className="h-4 w-4 text-theme" />
              <input
                type="text"
                placeholder="meltingpoint"
                className="w-40 sm:w-auto p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-theme"
              />
            </div>

            <div className="flex items-center gap-2">
              <input type="checkbox" className="h-4 w-4 text-theme" />
              <input
                type="text"
                placeholder="hfusion"
                className="w-40 sm:w-auto p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-theme"
              />
            </div>
          </div>

          {/* Run and Check Status on the same line */}
          <div className="flex flex-wrap gap-4 items-center">
            <button
              type="button"
              className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
              onClick={handleRun}
              disabled={loading}
            >
              Run
            </button>
            <button
              type="button"
              className={`min-w-[120px] max-w-[400px] w-40 sm:w-auto ${
                isChecking ? "bg-gray-400" : "bg-theme"
              } text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3`}
              onClick={handleCheckStatus}
              disabled={loading || isChecking}
            >
              {isChecking ? "Checking..." : "Check Status"}
            </button>
          </div>
          <div className="flex flex-wrap gap-4 items-start">
            <textarea
              className="w-full h-30 p-2 border border-gray-300 rounded-md resize-none bg-white"
              value={logStatus}
              readOnly
            />

            <button
              type="button"
              disabled={!isDownloadEnabled}
              className={`min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme ${
                isDownloadEnabled
                  ? " opacity-100"
                  : "opacity-50 hover:bg-[#A3A3A3]"
              }
                     text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3`}
              onClick={() => toast.info("Downloading the file...")}
            >
              Download
            </button>
          </div>
        </div>
      </div>

      <div className="divider"></div>

      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Solvent Screening</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-4">
          {/* Run Button */}
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            Run
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="relative">
          <h3 className="text-center">Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
        <div className="relative">
          <h3 className="text-center">Anti-Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 mt-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Solvent List Template</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            Download
          </button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="grid grid-cols-1 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">

              <div className="w-full flex flex-col items-center justify-center">
                <h3>Solubilities</h3>
                <div className="relative overflow-x-auto">
                  <ContourGraph plot={undefined} />
                </div>
              </div>
      </div>        
      <div className="flex flex-col sm:flex-row items-center gap-4 p-4 mb-6 rounded-lg shadow-sm justify-center">
        <div className="w-full sm:w-1/6">
          <span className="form-label">Download Results</span>
        </div>
        <div className="w-full sm:w-1/5">
          <div className="input-wrap select-outer">
            <select className="theme-ip w-full">
              <option value="">Select</option>
              <option value="Solvents">Solvents</option>
              <option value="Anti-Solvents">Anti-Solvents</option>
              <option value="Solubilities">Solubilities</option>
              {/* {lotNumberOptions?.map((name: any, index: number) => (
            <option key={index} value={name.lot_number}>{name?.lot_number}</option>
        ))} */}
            </select>
          </div>
        </div>
        <div className="w-full sm:w-1/6 mt-4 sm:mt-0">
          <button
            type="button"
            className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-full"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default CosmoRevised;
