import AddMaterial from "../components/helpContent/materialHelp/addMaterial"
import DashboardHelpContent from "../components/helpContent/materialHelp/dashboard"
import MaterialDashboardHelpContent from "../components/helpContent/materialHelp/materialDashboard";
import AddMaterialSolidPhaseHelp from "../components/helpContent/materialHelp/addMaterialSolidPhaseHelp";
import AddMaterialLiquidPhaseHelp from "../components/helpContent/materialHelp/addMaterialLiquidPhaseHelp";
import AddMaterialGasPhaseHelp from "../components/helpContent/materialHelp/addMaterialGasPhaseHelp";
import AddMaterialSolubilityHelp from "../components/helpContent/materialHelp/addMaterialSolubilityHelp";
import AddMaterialSpectralHelp from "../components/helpContent/materialHelp/addMaterialSpectralHelp";
import AddMaterialEnvironmentalDataHelp from "../components/helpContent/materialHelp/addMaterialEnvironmentalDataHelp";
import FindMaterialHelpContent from "../components/helpContent/materialHelp/findMaterialHelp";
import ListMaterialHelp from "../components/helpContent/materialHelp/listMaterialHelp";
import EquipmentDashboard from "../components/helpContent/materialHelp/equipmentDashboard";
import EquipmentModelsContent from "../components/helpContent/equipmentHelp/equipmentModelHelp";

// TODO: Can i change the name case ?? 

export const DashboardHelp = {
    title: 'Dashboard Help',
    content: <DashboardHelpContent/>
}

export const MaterialDashboardHelp = {
    title: 'Material Dashboard Help',
    content: <MaterialDashboardHelpContent/>
}

export const MaterialHelp = {
    title: 'Instructions to Add Material',
    content: <AddMaterial/>
}

export const MaterialSolidPhaseHelp = {
    title: 'Instructions for Material Solid Phase',
    content: <AddMaterialSolidPhaseHelp/>
}

export const MaterialLiquidPhaseHelp = {
    title: 'Instructions for Material Liquid Phase',
    content: <AddMaterialLiquidPhaseHelp/>
}
export const MaterialGasPhaseHelp = {
    title: 'Instructions for Material Gas Phase',
    content: <AddMaterialGasPhaseHelp/>
}

export const MaterialInnerSolubilityHelp = {
    title: 'Instructions for Material Sub Solubility',
    content: <AddMaterialSolubilityHelp/>
}

export const MaterialSpectralInfoHelp = {
    title: 'Instructions for Material Spectral Information',
    content: <AddMaterialSpectralHelp/>
}

export const MaterialEnvironmentalDataHelp = {
    title: 'Instructions for Material Environmental Data',
    content: <AddMaterialEnvironmentalDataHelp/>
}

export const MaterialSolubilityHelp = {
    title: 'Instructions for Material Solubility Models',
    content: 'Coming Soon...'
}

export const EquipmentModelsHelp = {
    title: 'Instructions for Equipment Models',
    content: <EquipmentModelsContent/>
}

export const MaterialListHelp = {
    title: 'Material Screening Help',
    content: <ListMaterialHelp/>
}

export const FindMaterialHelp = {
    title: 'Find Material Help',
    content: <FindMaterialHelpContent/>
}

export const ViewMaterialHelp = {
    title: 'Instructions to View Material',
    content: 'Coming Soon...'
}

export const EquipmentDashboardHelp = {
    title: 'Help for Equipment Dashboard',
    content: <EquipmentDashboard/>
}
