
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// components
import MultiFileUpload from '../common/MultiFileUpload';
import CustomLoader from '../common/CustomLoader';
import Dropdown from '../common/Dropdown';

// charts
import LineChart from '../charts/LineChart';

// redux action
import { alertOpenAction, alertCloseAction } from '../../redux/actions';

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from '../../utils';
import { limitDigits } from '../../utils/common';

import LaurasModels from './LaurasModels';
import MaterialService from "../../services/materialService";
import Select from 'react-select';
import CmacModels, { ResultsTable, useCMACModel } from '../../pages/materials/material-solubility/cmac-models';
import { RootState } from '../../redux/store';
import { materialSolubilityModelOptions } from '../../utils/materialHelper';

function useSolvents() {
  const [loading, setLoading] = useState(false);
  const [solvents, setSolvents] = useState<any>([]);
  useEffect(() => {
    setLoading(true);
    MaterialService.getSolvents().then(res => {
      if (res.status === 200 && res.data.body.solvensts) {
        setSolvents(res.data.body.solvensts || []);
      }
    });

  }, []);
  return solvents;
}
function useMaterial() {
  const { item: material } = useSelector((state: RootState) => state.material);
  return {
    material: Array.isArray(material) ? null : material,
  };
}
const TakedaModels = (props: any) => {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const { headerFilters, model3Saved, setCredits } = props;
  const solvents = useSolvents();
  const { material } = useMaterial();
  const [simulateLoading, setSimulateLoading] = useState(false);

  const queryObj = useMemo(() => (material ? {
    "uid": material.uid,
    "vendor_name": material.vendor_name,
    "material_name": material.material_name,
    "lot_number": material.lot_number,
    "material_id": material.id,
  } : null), [material]);
  const {
    saveCMACModel,
    loadingCMACData,
    cmacData,
    refreshCMACData,
    calculateSolubility,
    deleteCMACRecord,
  } = useCMACModel(queryObj);

  useEffect(() => {
    if (typeof setCredits === 'function') {
      if (methods.control._formValues.model === 'Model 3') {
        setCredits('Model created and deployed by Khelan Patel, contact at khelan.patel@gmail.com for any questions.');
      } else {
        setCredits('');
      }
    }
  }, [methods.control._formValues.model]);

  return (

    <><div>

      {<div className="row">
        {(props.loading || loadingCMACData || simulateLoading) && <CustomLoader />}
        <div className="col-md-4">
          <div className="">
            <div className="col-md-12">
              <Dropdown
                isRequired
                label='Model'
                htmlFor='model'
                fieldName='model'
                control={methods.control}
                options={materialSolubilityModelOptions}
                defaultValue={methods.control._formValues.model}
              />
            </div>

            <div className="col-md-12">{(methods.control._formValues.model == 'CMAC' || methods.control._formValues.model == 'ETC')
              && <CmacModels setCloseFn={props.setCloseFn} setSubmitFn={props.setSubmitFn} model={methods.control._formValues.model}
                saveCMACModel={saveCMACModel}
                refreshCMACData={refreshCMACData}
                calculateSolubility={calculateSolubility}
                simulateLoading={simulateLoading}
                setSimulateLoading={setSimulateLoading}
              />}</div>

            {methods.control._formValues.model === 'Model 3' && <div className="col-md-12">
              <>
                <div className="form-group">
                  <MultiFileUpload
                    title='Upload image from Unchained Junior in PNG format'
                    name="upload"
                    acceptFormat="image/png"
                    attachments={methods.control._formValues.attachments.model3}
                    handleFileChange={(e) => {
                      const invalidFormat = Array.from(e?.target?.files ?? [])?.find((file: any) => file.type !== 'image/png');
                      if (invalidFormat) {
                        dispatch(alertOpenAction('Invalid file format. Please select .png extension file.', 'error'));
                        setTimeout(() => dispatch(alertCloseAction()));
                        return;
                      }
                      methods.setValue('attachments.model3', [...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                          const fileSize = e?.target?.files?.[key]?.size ?? 0;
                          return {
                            category: 'Solubility Model3',
                            file: e?.target?.files?.[key],
                            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                          }
                        })], { shouldDirty: true, shouldTouch: true })
                    }}
                    removeFile={(index: number) => {
                      methods.setValue('attachments', methods.control._formValues.attachments.model3.filter((attachment: any, i: number) => i !== index))
                      methods.setValue('solubility_value', '');
                      methods.setValue('turbidity_measured', '');
                    }}
                    multiple={false}
                  />
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="ip-wrap" htmlFor="turbidity_measured">
                      <span className="form-label" title="Enter the turbidity value">Enter turbidity value from the Unchained Junior</span>
                      <Controller
                        name="turbidity_measured"
                        control={methods.control}
                        render={({ field }) => <input type="number"
                          onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field}
                          className="theme-ip" />}
                      />
                    </label>
                  </div>
                </div>
              </>
            </div>
            }
            {(methods.control._formValues.model !== 'CMAC' && methods.control._formValues.model !== 'ETC') &&
              <>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="ip-wrap" htmlFor="solvent">
                      <span className="form-label" title="Select Solvent">Solvent</span>
                      <Controller
                        name="solvent"
                        control={methods.control}
                        defaultValue={methods.control._formValues.solvent}
                        render={({ field }) => (
                          <Select {...field} value={methods.control._formValues.solvent} options={solvents.map((solvent: any) => ({
                            label: solvent.material_name, value: solvent.material_name

                          }))}
                            placeholder={"Type Solvent..."} className="basic-single"
                            classNamePrefix="select" />
                        )}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="ip-wrap" htmlFor="temperature">
                      <span className="form-label" title="Select Temperature">Temperature(C)</span>
                      <div className="flex mt-3">
                        <div className="slidecontainer w-full">
                          <Controller
                            name="temperature"
                            control={methods.control}
                            render={({ field }) => (
                              <input
                                type="range"
                                min="1"
                                max="100"
                                className="slider w-full block"
                                id="solubility"
                                value={methods.control._formValues.temperature}
                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                              />
                            )}
                          />
                        </div>
                        <div className="value ml-1 w-10 text-right">
                          {methods.control._formValues.temperature}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="ip-wrap" htmlFor="solubility_value">
                      <span className="form-label">Solubility(mg/ml)</span>
                      <Controller
                        name="solubility_value"
                        control={methods.control}
                        render={({ field }) =>
                          <input className="theme-ip" {...field} readOnly={true} />}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group custome-fs">
                    <button title="Run" onClick={props.handleSolubility}
                      className="theme-btn mb-6 w-full btn-md">Run
                    </button>
                  </div>
                </div>
              </>}
          </div>
        </div>
        {methods.control._formValues.model == 'Model 3' &&
          <div className="col-md-8">
            <LaurasModels headerFilters={headerFilters} model3Saved={model3Saved}></LaurasModels>
          </div>}
        {methods.control._formValues.model == 'Model 1' &&
          <div className="col-md-8">
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group">
                  <label className="custom-checkbox">
                    <Controller
                      name="visual"
                      control={methods.control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input className="ip" type="checkbox"
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value} />
                      )}
                    />
                    <span className="check"></span>
                    <span className="txt form-label">Visual</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-8 ">
                {
                  methods.control._formValues.visual && (
                    <div className="chart">
                      <LineChart
                        data={{
                          labels: methods.control._formValues.graph?.labels ?? [],
                          // labels: Array.from(new Set([
                          //     ...methods.control._formValues.graph?.ethanol?.map((item: any) => item.x),
                          //     ...methods.control._formValues.graph?.methanol?.map((item: any) => item.x),
                          // ])).sort((a: number, b: number) => a > b ? 1 : -1),
                          datasets: [
                            {
                              label: "Ethanol",
                              data: methods.control._formValues.graph?.ethanol ?? [],
                              fill: true,
                              backgroundColor: "rgba(255,36,42,0.2)",
                              borderColor: "rgba(255,36,42,1)"
                            },
                            {
                              label: "Methanol",
                              data: methods.control._formValues.graph?.methanol ?? [],
                              fill: true,
                              backgroundColor: "rgba(0,0,0,0.2)",
                              borderColor: "rgba(0,0,0,1)"
                            }
                          ]
                        }}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        }
        {
          methods.control._formValues.model === "CMAC" && <div className="col-md-8">
            {
              methods.control._formValues.model === "CMAC" && loadingCMACData &&
              <div className="w-full h-full flex justify-center items-center">
                <div className="h-12 w-12 border-4 rounded-full animate-spin" style={{
                  borderTopColor: "#ed1c24",
                }}>
                </div>
              </div>
            }
            {
              methods.control._formValues.model === "CMAC" && (cmacData && cmacData.length ?
                <ResultsTable results={cmacData} refresh={refreshCMACData} deleteCMACRecord={deleteCMACRecord} />
                : <h1 className="text-center">No Data Found !</h1>)
            }
          </div>
        }
      </div>}
    </div>
    </>
  )
}

export default TakedaModels;